<template>
  <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link class="brand-logo">
          <img src="/favicon.png">
          <h2 class="brand-text text-primary ml-1">
            Pitjarus Teknologi
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
            lg="7"
            class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
                fluid
                :src="imgUrl"
                alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
            lg="5"
            class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
          >
            <b-card-title
                class="mb-1 font-weight-bold"
                title-tag="h2"
            >
              Welcome to Jarvist Lite Enterprise 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Please Sign-in to your Account
            </b-card-text>

            <!-- form -->
            <validation-observer
                ref="loginForm"
                #default="{invalid}"
            >
              <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                    label="Email"
                    label-for="login-email"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="required|email"
                  >
                    <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required"
                  >
                    <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                >
                  Sign In
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getUserData, encryptSSO } from '@/auth/utils'

import { makeToast } from '@/@fake-db/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: localStorage.getItem('rememberMe') ?? false,
      password: localStorage.getItem('rememberPassword') ?? '',
      userEmail: localStorage.getItem('rememberEmail') ?? '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      showLoading: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.showLoading = true
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
              .then(async response => {
                this.showLoading = false
                const { accessToken, refreshToken } = response.data.data
                useJwt.setToken(accessToken)
                useJwt.setRefreshToken(refreshToken)
                let userData = await useJwt.getUserData()
                userData = userData.data.data

                localStorage.setItem('signature', userData)
                const user = getUserData()
                this.$ability.update(user.ability)
                localStorage.setItem('rememberMe', this.status)

                if (this.status) {
                  localStorage.setItem('rememberEmail', this.userEmail)
                  localStorage.setItem('rememberPassword', this.password)
                } else {
                  localStorage.removeItem('rememberEmail')
                  localStorage.removeItem('rememberPassword')
                }
                const code = btoa(encryptSSO(JSON.stringify({
                  email: this.userEmail,
                  password: this.password,
                })))
                // window.location.href = `https://dashboard.salesjarus.com/index.php/login/sso/${code}`
                window.location.href = `https://console.salesjarus.com/billing`
              }).catch(e => {
            this.showLoading = false
            const message = e.response ? e.response.data.error : e.message
            makeToast(this, message, 'AlertTriangleIcon', 'danger')
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
